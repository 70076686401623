import isIP from './isIP';
import merge from './merge';
import isFQDN from './isFQDN';
import assertString from './assertString';

const default_url_options = {
	protocols: ['http', 'https', 'ftp'],
	require_tld: true,
	require_protocol: false,
	require_host: true,
	require_port: false,
	require_valid_protocol: true,
	allow_underscores: false,
	allow_trailing_dot: false,
	allow_protocol_relative_urls: false,
	validate_length: true,
};

const wrapped_ipv6 = /^\[([^\]]+)\](?::([0-9]+))?$/;

const isRegExp = (obj) => {
	return Object.prototype.toString.call(obj) === '[object RegExp]';
};

const checkHost = (host, matches) => {
	for (let i = 0; i < matches.length; i++) {
		let match = matches[i];
		if (host === match || (isRegExp(match) && match.test(host))) {
			return true;
		}
	}
	return false;
};

const isValidUrl = (url, options) => {
	assertString(url);
	if (!url || /[\s<>]/.test(url)) {
		return false;
	}
	if (url.indexOf('mailto:') === 0) {
		return false;
	}
	options = merge(options, default_url_options);

	if (options.validate_length && url.length >= 2083) {
		return false;
	}

	let protocol, auth, host, hostname, port, port_str, split, ipv6;

	split = url.split('#');
	url = split.shift();

	split = url.split('?');
	url = split.shift();

	split = url.split('://');
	if (split.length > 1) {
		protocol = split.shift().toLowerCase();
		if (options.require_valid_protocol && options.protocols.indexOf(protocol) === -1) {
			return false;
		}
	} else if (options.require_protocol) {
		return false;
	} else if (url.substr(0, 2) === '//') {
		if (!options.allow_protocol_relative_urls) {
			return false;
		}
		split[0] = url.substr(2);
	}
	url = split.join('://');

	if (url === '') {
		return false;
	}

	split = url.split('/');
	url = split.shift();

	if (url === '' && !options.require_host) {
		return true;
	}

	split = url.split('@');
	if (split.length > 1) {
		if (options.disallow_auth) {
			return false;
		}
		auth = split.shift();
		if (
			auth.indexOf(':') === -1 ||
			(auth.indexOf(':') >= 0 && auth.split(':').length > 2)
		) {
			return false;
		}
	}
	hostname = split.join('@');

	port_str = null;
	ipv6 = null;
	const ipv6_match = hostname.match(wrapped_ipv6);
	if (ipv6_match) {
		host = '';
		ipv6 = ipv6_match[1];
		port_str = ipv6_match[2] || null;
	} else {
		split = hostname.split(':');
		host = split.shift();
		if (split.length) {
			port_str = split.join(':');
		}
	}

	if (port_str !== null) {
		port = parseInt(port_str, 10);
		if (!/^[0-9]+$/.test(port_str) || port <= 0 || port > 65535) {
			return false;
		}
	} else if (options.require_port) {
		return false;
	}

	if (!isIP(host) && !isFQDN(host, options) && (!ipv6 || !isIP(ipv6, 6))) {
		return false;
	}

	host = host || ipv6;

	if (options.host_whitelist && !checkHost(host, options.host_whitelist)) {
		return false;
	}
	if (options.host_blacklist && checkHost(host, options.host_blacklist)) {
		return false;
	}

	return true;
};

export default isValidUrl;
