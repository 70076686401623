import merge from './merge';
import assertString from './assertString';

const default_fqdn_options = {
	require_tld: true,
	allow_underscores: false,
	allow_trailing_dot: false,
	allow_numeric_tld: false,
};

const isFQDN = (str, options) => {
	assertString(str);
	options = merge(options, default_fqdn_options);

	/* Remove the optional trailing dot before checking validity */
	if (options.allow_trailing_dot && str[str.length - 1] === '.') {
		str = str.substring(0, str.length - 1);
	}
	const parts = str.split('.');
	const tld = parts[parts.length - 1];

	if (options.require_tld) {
		// disallow fqdns without tld
		if (parts.length < 2) {
			return false;
		}

		if (!/^([a-z\u00a1-\uffff]{2,}|xn[a-z0-9-]{2,})$/i.test(tld)) {
			return false;
		}

		// disallow spaces && special characers
		if (/[\s\u2002-\u200B\u202F\u205F\u3000\uFEFF\uDB40\uDC20\u00A9\uFFFD]/.test(tld)) {
			return false;
		}
	}

	// reject numeric TLDs
	if (!options.allow_numeric_tld && /^\d+$/.test(tld)) {
		return false;
	}

	return parts.every((part) => {
		if (part.length > 63) {
			return false;
		}

		if (!/^[a-z_\u00a1-\uffff0-9-]+$/i.test(part)) {
			return false;
		}

		// disallow full-width chars
		if (/[\uff01-\uff5e]/.test(part)) {
			return false;
		}

		// disallow parts starting or ending with hyphen
		if (/^-|-$/.test(part)) {
			return false;
		}

		if (!options.allow_underscores && /_/.test(part)) {
			return false;
		}

		return true;
	});
};

export default isFQDN;
